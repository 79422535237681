@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
  overflow: hidden;
  background-color: white;
  font-family: 'Roboto', sans-serif;
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
}

.graph{
  width: calc(100% - 250px);
  justify-content: center;
  display: flex;
}

.messageContainerHeader{
  background-color: white;
}

.messageContainer{
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  box-sizing: border-box;
  border-left: solid 1px #dfdfdf;
  background-color: white;
  z-index: 5;
  overflow-y: hidden;
  width: 250px;
  display: flex;
  flex-direction: column;
}

.img-logo {
  width: 250px;
  margin: 0 auto;
  position: absolute;
  z-index: 99999;
  right: 0;
  bottom: 0;
  padding: 20px 0;
  overflow: hidden;
  box-sizing: border-box;
}
.img-logo img {
  user-select: none;
  -webkit-user-drag: none;
  display: block;
  margin: 0 auto;
  width: 180px;
}
.img-logo img:hover {
  cursor: pointer;
}

.messageContainerTitle{
  text-align: center;
  margin-top: 0px;
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 0px;
}

.messages {
  flex-grow: 1;
  overflow-y: auto;
}

.messageBox{
  border-bottom: solid #dfdfdf 1px;
  padding: 16px;
}
.messageBox:first-child{
  border-top: solid #dfdfdf 1px;
}
.messageBox:nth-child(even){
  background-color: #F4D3D3;
}
.messageBox:nth-child(odd){
  background-color: #F2AAAA;
}


.messageTitle{
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 0px;
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.messageText{
  font-size: 14px;
  margin-top: 0px;
  padding-top: 0px;
}

.react-flow__handle{
  border: solid 1px black !important;
  opacity: 0 !important;
  background: White !important;
}

.react-flow__node.react-flow__node-default strong {
  font-weight: 500;
}

.react-flow{
  min-width: 500px;
  min-height: 100vh;
  width: 100%;
  height: 100%;
}
.resetViewButton{
  position: fixed;
  width: 150px;
  left: 15px;
  top: 15px;
  z-index: 10;
  padding: 12px;
  background-color: #FA4E4E;
  color: white;
  outline: none;
  font-weight: bold;
  cursor: pointer;
  border: solid 1px black;
}
.resetViewButton:hover{
  background-color: #DF8787;
}